import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChat } from '@common/models/Chat/IChat';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import { IChat, IChatCreate } from '@common/models/Chat/IChat';
import { ApiService } from 'quangular';
import { IDocument } from '@common/models/Document/IDocument';
import { IChatInteraction } from '@common/models/Chat/IChatInteraction';
import { IChatDocument } from '@common/models/Chat/IChatDocument';
import { IChatRequest } from '@common/models/Chat/IChatRequest';
import { IAddDocumentToChat } from '@common/models/Chat/IAddDocumentToChat';
import { IAddDoctoChatAPIResp } from '@common/models/Chat/IAddDoctoChatAPIResp';
import { IInteractionFeedback } from '@common/models/Chat/IInteractionFeedback';
import { InteractionType } from '@common/enums/InteractionType';
import { ChatType } from '@common/enums/ChatType';
import { IDeltaTable } from '@common/models/Document/IDeltaTable';
import { IESGDocuments, IResponseESGFilters, ITableTag } from '@common/models/Document/IESGDocuments';
import { IUser } from '@common/models/IUser';
import { IChatConfig } from '@common/models/Chat/IChatConfig';
import { ITableRow } from '@common/models/Document/ITableRow';
@Injectable({
    providedIn: 'root',
})
export class ChatServices {
    private activeChat: IChat;
    private activeChatObs = new Subject<IChat>();
    private chatDocumentsList: IDocument [] = [];
    public chatType:ChatType = ChatType.Normal;
    public interactionType : ChatType = ChatType.Normal;
    public chatConfig :IChatConfig = {};
    excelType = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel'
      ]

      csvType=[
        'text/csv'
      ]
    constructor(private http: HttpClient, private apiService: ApiService) {}
    getAllChats(start_date: number, end_date: number): Observable<IChat[]> {
        return this.apiService.get<IChat[]>(`Chat/?start_date=${start_date}&end_date=${end_date}&chatType=${this.chatType}`);
    }
    getChatImage(chatId:string,uuidImageName:string): Observable<Buffer> {
      return this.apiService.get<Buffer>(`Chat/${chatId}/Image/${uuidImageName}`);
    }
    // getSearchChats(searchText:string): Observable<IChat[]> {
    //   return this.apiService.get<IChat[]>(`Chat?searchText=${searchText}`);
    // }
    // getSearchChatInteractions(): Observable<IChatInteraction[]> {
    //   return this.apiService.get<IChatInteraction[]>(`Chat`);
    // }
    //   getSingleChat(chatId:string): Observable<IChat> {
    //     return this.apiService.get<IChat>(`Chat/${chatId}` );
    // }


    createNewChat(): Observable<IChat> {
      let postBody:Partial<IChat>  = {}
      if(this.chatType === ChatType.QEC || this.chatType === ChatType.ESG || this.chatType === ChatType.codeInterpreter || this.chatType === ChatType.HRDocuments || this.chatType === ChatType.agenticWorkflow){
         postBody= {
          chatType : this.chatType
        }
        if(this.chatType === ChatType.ESG && this.chatConfig){
          postBody.chatConfig = this.chatConfig
        }
      }
      return this.apiService.post('Chat', postBody );
    }

    updateChatTypeStatus(chatType:ChatType) {
      this.chatType = chatType
    }
    getChatType():ChatType {
      return this.chatType
    }
    getChatInteractions(params: string): Observable<IChatInteraction[]> {
        return this.apiService.get<IChatInteraction[]>(`Chat/${params}`);
    }
    deleteChat(chatId: string): Observable<Response> {
        return this.apiService.delete(`Chat/${chatId}`);
    }
    sendQuestion(inputText: string, chatId: String,interactionType:InteractionType): Observable<IChatInteraction> {
        const payload: IChatRequest = {
            requestText: inputText,
            askedTime: new Date(),
        };
        return this.apiService.post(`Chat/${chatId}/question?interactionType=${interactionType}`, payload);
    }
    getQuestionResponse(
        chatId: String,
        questionId: String,
        regeneration:boolean,
        documentChat:ChatType
    ): Observable<IChatInteraction> {
      if(this.chatType === ChatType.QEC || this.activeChat?.chatType === ChatType.QEC || this.chatType === ChatType.codeInterpreter || this.chatType === ChatType.HRDocuments){
        documentChat = this.chatType
      }
      if(this.chatType === ChatType.ESG && documentChat!==ChatType.ESGStructuredUnstructuredDocs){
        documentChat = ChatType.ESG
      }

      this.interactionType = documentChat
        return this.apiService.get<IChatInteraction>(
            `Chat/${chatId}/interaction/${questionId}?documentChat=${documentChat}&regenerate=${regeneration}`
        );
    }
    getQuestionResponseSummary(
        chatId: String,
        questionId: String,
        documentId:string,
        documentChat:ChatType
    ): Observable<IChatInteraction> {
      if(this.chatType === ChatType.QEC || this.activeChat?.chatType === ChatType.QEC || this.chatType === ChatType.codeInterpreter){
        documentChat = this.chatType
      }
      if(this.chatType === ChatType.ESG){
        documentChat = ChatType.ESG
      }
      this.interactionType = documentChat
        return this.apiService.get<IChatInteraction>(
            `Chat/${chatId}/interaction/${questionId}?documentChat=${documentChat}&documentId=${documentId}`
        );
    }

    chatFeedback(chatId:string,interactionId:string,payload:IInteractionFeedback): Observable<IChatInteraction> {
        return this.apiService.post( `Chat/${chatId}/interaction/${interactionId}/feedback`, payload);
    }

    getChatDocuments(chatId: string): Observable<IDocument[]> {
        return this.apiService.get<IDocument[]>(`Document/Chat/${chatId}`);
    }

    getDocumentDetails(docId: string,chatId:string, type:string): Observable<IDocument> {
      if(this.excelType.includes(type)){
        return this.apiService.get<IDocument>(`Document/${docId}?chatId=${chatId}`);
      }else{
        return this.apiService.get<IDocument>(`Document/${docId}`);
      }
    }

    UserMe(): Observable<IUser> {
        return this.apiService.get<IUser>('User/Me ');
    }
    uploadFile(params: FormData, forceUpload?: boolean): Observable<IDocument> {
        let url = `Document`;
        if (forceUpload) {
            url = `Document?forceUpload=${forceUpload}`;
        }
        return this.apiService.post(url, params);
    }

    sendFileQuestion(params: FormData,chatId:string): Observable<IChatInteraction> {
      return this.apiService.post(`Chat/fileQuestion?chatId=${chatId}`, params);
    }

    retriggerUploadFile(doc_Id:string): Observable<IDocument> {
        let url = `Document/${doc_Id}/retrigger`;
        return this.apiService.put(url,{});
    }

    updateChatDocuments(
        docs: IChatDocument[],
        chatId: string,
        tables?: IDeltaTable[],
        esgTags? : ITableTag[], chatConfig? : IChatConfig
    ): Observable<IAddDoctoChatAPIResp> {
        const payload: IAddDocumentToChat = {
            document: docs,
        };
        if(tables && tables.length > 0){
          payload.tables = tables
        }
        if(esgTags){
          payload.ESGTags = esgTags
        }
        if(chatConfig){
          payload.chatConfig = chatConfig
        }

        return this.apiService.post(`Document/chat/${chatId}`, payload);
    }

    deleteChatDocs(chatId: string, docId: string): Observable<Response> {
        return this.apiService.delete(
            `document/chat/${chatId}/document/${docId}`
        );
    }

    getActiveChatObs(): Observable<IChat> {
        return this.activeChatObs.asObservable();
    }

    updateActiveChatObs(docs: IChat) {
        this.activeChatObs.next(docs);
    }

    getChatDocumentList() {
        return this.chatDocumentsList;
    }

    updateChatDocumentList(docs: IDocument[]) {
        this.chatDocumentsList = docs;
    }

    updateActiveChat(chat: IChat) {
        this.activeChat = chat;
    }

    getActiveChat() {
        return this.activeChat;
    }

    editChatDetails(chatId:string,payload:Partial<IChat>){
        return this.apiService.put(`Chat/${chatId}`,payload)
    }

    getAllDocs(search?: string,markerId?:number): Observable<IDocument[]> {
        let url=`Document?pageSize=15`
        if(markerId){
            url=`Document?pageSize=15&markerId=${markerId}`
        }
        if(search && search.trim()!=''){
            url=`Document?searchText=${search.trim()}`
        }
        return this.apiService.get<IDocument[]>(
           url
        );
    }

    getChatInteractionById(chatId: string, interactionId:string): Observable<IChatInteraction> {
      return this.apiService.get<IChatInteraction>(`Chat/chatInteraction/${chatId}/${interactionId}`);
  }

  getDeltaTableById(tableId:string): Observable<IDeltaTable> {
    return this.apiService.get<IDeltaTable>(`DeltaTable/${tableId}`);
  }

  getESGDocuments(): Observable<IResponseESGFilters> {
    return this.apiService.get<IResponseESGFilters>(`DeltaTable/ESGtable/documents`);
  }

  getESGDocumentByID(documentId:string): Observable<IESGDocuments> {
    return this.apiService.get<IESGDocuments>(`DeltaTable/ESGtable/documents/${documentId}`);
  }

  getDataBySqlCode(sqlCode: string): Observable<ITableRow> {
    return this.apiService.get<ITableRow>(`Chat/interaction/sqlCodeData?sqlCode=${sqlCode}`);
  }

  getHRDocuments(): Observable<IChatDocument[]> {
    return this.apiService.get<IChatDocument[]>(`Chat/hr/Documents`);
  }
}
