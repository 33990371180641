import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {
    AuthModule,
    ArrowModule,
    HeaderModule,
    MainMenuModule,
    MainLayoutModule,
    ReleasenotesModule,
    QUANGULAR_CONFIG,
    QuangularConfig,
    LoaderModule,
} from 'quangular';
import { AppComponent } from './app.component';
import { environment } from '../../src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from './common/shared.module';
const menuItems = [
    { title: 'Chat', link: 'chat', exact: false,  },
    { title: 'Datasets', link: 'datasets', exact: false },
    // { title: 'Usage Analytics', link: 'admin', exact: false },
];

const config: QuangularConfig = {
    environment: environment,
    appRoles: ['GlobalAdmin', 'User'],
    mainMenu: menuItems,
    roleRoutes: {
        GlobalAdmin: ['chat', 'datasets'],
        User: ['chat', 'datasets'],
    },
};

@NgModule({
    imports: [
        AuthModule.forRoot(config),
        HttpClientModule,
        BrowserModule,
        ArrowModule,
        HeaderModule,
        MainMenuModule,
        AppRoutingModule,
        MainLayoutModule,
        ReleasenotesModule,
        BrowserAnimationsModule,
        StyleClassModule,
        LoaderModule,
        ToastModule,
        ConfirmDialogModule,
        SharedModule,
    ],
    providers: [
        {
            provide: QUANGULAR_CONFIG,
            useValue: config,
        },
    ],
    exports: [LoaderModule],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}
